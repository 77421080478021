/**
 * axios with a custom config.
 */

import axios from 'axios';
import { auth } from '@strapi/helper-plugin';

const instance = axios.create({
  baseURL: process.env.STRAPI_ADMIN_BACKEND_URL,
});

console.log("config",
{
  Authorization: `Bearer ${auth.getToken()}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

instance.interceptors.request.use(
  async config => {
    config.headers = {
      Authorization: `Bearer ${auth.getToken()}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => response,
  error => {
    // whatever you want to do with the error
    console.log("Error in axiosInstance: ", error);
    // if (error.response?.status === 401) {
    //   auth.clearAppStorage();
    //   window.location.reload();
    // }

    if (error.response?.status === 400) {
      throw new Error('You violate a unique constraint here!. Check for unique fields in the Content-Type you are uploading to!')
    }

    throw error;
  }
);

export default instance;